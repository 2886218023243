<template>
    <!-- 号码池页面 -->
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="虚拟号地区"  v-if="uidT!='100529'&&$store.state.userType=='system'"     >
        <virtualRegion /> 


      </a-tab-pane>
      <a-tab-pane key="2" tab="虚拟号列表">
        <virtualNumber /> 

      </a-tab-pane>

    </a-tabs>
   
  </template>
  <script>
 
 import virtualNumber from "./virtualNumberCom/virtualNumber.vue";
 import virtualRegion from "./virtualNumberCom/virtualRegion.vue";
  import {virtualNumberList} from '@/api/web/virtualNumber'
  import {relationshipUnbinding} from '@/api/web/virtualNumber'
  import {message} from 'ant-design-vue'
  import {IconFont} from '@/utils/iconfont'
  import {toRaw} from "vue";
  import { getDate } from '@/utils/timeToChoose.js'
  import userts from '@/api/web/user'
  export default {
    name: 'Number',
    components: {
      virtualNumber,
      IconFont,
      virtualRegion
    },
    data() {
   
      return {
        uidT:this.$store.state.user.uid,
        label: [],
        
        tableData: [], //数据列表
       
        theQueryDialog: false, //弹窗--查询
        theQueryDialog1:false,//弹窗--批量
        activeNames: [], //折叠面板
        
        tableHeight: '', //表格高度
        operationType: 0, //弹窗提交按钮文字状态
        currentPage: 1, //分页
        total: 0, //总数据条数
       
        industryTreeProps: {
          multiple: false,
          checkStrictly: true,//Select any level of options
        },
        unIndustryTreeProps: {
          multiple: true,
          checkStrictly: true,//Select any level of options
        },
        user_list: [], 
        multipleSelection: [], // 选中小号的集合
     
        
        searchForm: {
          page: 1,
          pageSize: 10,
          //搜索项
          uid:'',
          authStatus:'',
          status: '',
          telA:'',
          telX:'',
          bindId:'',
          createTimeBegin:'',
          createTimeEnd:'',
        //   ----
        },
        valueTime: [], //选中时间段
        allIds: [],
        defaultTime: [
        new Date(2000, 1, 1),
        new Date(2000, 2, 1),
           ], //默认时间 '12:00:00', '08:00:00'
       
        loading: true, // loding 状态启用
        importType: 1, 
      }
    },
    created() {
      this.tableHeight = this.$store.state.tableHeight
    },
    mounted() {
      console.log(this.$store.state.user,'0009999')
      // amount.industryidlist().then((res) => {
      //   this.label = res.data
      // })
        
    
      this.getNumberList()
      this.getUserList()
      this.getUserList1()
     
    },
    computed: {
     
     
    },
    methods: {  
      // 批量解除
      packBranch() { 
        if (this.allIds.length < 1) {
          this.$notify({
            title: '失败',
            message: '请先勾需要解绑的号码',
            type: 'waring',
          })
        } else {
        
        this.theQueryDialog1=true
        }
      },
      searchTapFun1(){
       this.confirmEvent1()
       this.theQueryDialog1 = false
      },
      // 列表多选--操作
      handleSelectionChange(val) {
        let newIds = []
        val.forEach((item) => {
          newIds.push(item.id)
        })
        this.multipleSelection = val
        this.allIds = newIds
      },
     // 获取用户列表
    getUserList1() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data
      })
    },
      // 获取用户列表
      getUserList() {
        virtualNumberList({}).then((res) => {
          console.log(res,'2323123123');
          this.user_list = res.data.records

        })
      },
     
      // 重置搜索项
      searchTapReset(kind) {
        this.searchForm = {
          page: this.currentPage,
          pageSize: 10,
          authStatus: '',
          uid: '',
          telA: '',
          telX:'',
          bindId:'',
          createTimeBegin:'',
          createTimeEnd:''
        }
        this.valueTime=""
        this.searchArea = []
        if (kind == 1) {
          this.getNumberList()
          this.theQueryDialog = false
        }
      },
      // 搜索项
      searchTapFun() {
        if(this.valueTime){
        this.searchForm.createTimeBegin = this.valueTime[0];
        this.searchForm.createTimeEnd = this.valueTime[1];
      }else{
        this.searchForm.createTimeBegin = '';
        this.searchForm.createTimeEnd = '';
      } 
        this.getNumberList()
        this.theQueryDialog = false
        this.valueTime=""
      },
      // 解除绑定
    confirmEvent(id) {
      if(id){
        this.allIds[0]=id
      }
      let dataName=''
     this.$confirm('将解除绑定，是否继续?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
      }).then(() => {
    relationshipUnbinding({ ids:this.allIds }) // 将从点击事件中获取的 ids 参数传递给接口
    .then((res) => {
      this.dataName=res.data[0]
      this.$notify({
                    title: '成功',
                    message: this.dataName,
                    type: 'success',
                  })
                  this.allIds=[]
      this.getNumberList()
    });
  });
},
     // 解除绑定
     confirmEvent1(id) {
      if(id){
        this.allIds[0]=id
      }
      let dataName=''
      relationshipUnbinding({ ids:this.allIds }) // 将从点击事件中获取的 ids 参数传递给接口
    .then((res) => {
      this.dataName=res.data[0]
      this.$notify({
                    title: '成功',
                    message: this.dataName,
                    type: 'success',
                  })
                  this.allIds=[]
      this.getNumberList()
    });
},
    
      getauthStatus(key){
        switch (key) {
        case 0:
        return '绑定中'
        break;
        case 1:
        return '绑定成功'
        break;
        case 2:
        return '绑定失败'
        break;
        case 3:
        return '已解绑'
        break;
    default:
    return '--'
        break;
}
      },
      // 获取列表
      getNumberList() {
        this.loading = true
        virtualNumberList({
              ...this.searchForm,
            })
            .then((res) => {
              this.tableData = res.data.records
              this.loading = false
              this.total = res.data.total
            })
      },
      // 分页--条数
      handleSizeChange(val) {
        this.searchForm.pageSize = val
        this.getNumberList()
      },
      // 分页--页码
      handleCurrentChange(val) {
        this.searchForm.page = val
        this.currentPage = val
        this.getNumberList()
      },
    },
  }
  </script>
  <style lang="scss" scoped>
  .number {
    padding-right: 10px;
  }
  
  .showIdsList {
    min-height: 30px;
    max-height: 260px;
    overflow-y: overlay;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding-left: 16px;
  }
  
  .title-buttom {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
  }
  
  .cancel {
    color: #637dff;
    border: #637dff solid 1px;
  }
  
  .confirmAdd {
    background: #637dff;
    color: #fff;
  }
  
  .form-out-box {
    height: 400px;
    margin-bottom: 20px;
    overflow-y: overlay;
  }
  
  .yes-green-span {
    display: inline-block;
    height: 20px;
    padding: 0 5px;
    line-height: 19px;
    color: #409eff;
    background: #ecf5ff;
    border: 1px solid #ecf5ff;
  }
  
  .no-red-span {
    display: inline-block;
    height: 20px;
    padding: 0 5px;
    line-height: 19px;
    background: #f4f4f5;
    border: 1px solid #f4f4f5;
  }
  
  .redItem {
    color: #f0142f;
  }
  
  .showErrList {
    height: 200px;
    overflow: scroll;
    width: 250px;
  }
  
  ::v-deep(.checkbox .el-checkbox) {
    display: block;
  }
  
  .noticetext span {
    color: #f0142f;
  }
  </style>
  